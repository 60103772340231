<template>
  <v-container fluid class="width-80">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Integrations
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 70vh; position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="12" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Configuration
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <v-row>
            <v-col md="2">
              <v-img
                :lazy-src="$defaultImage"
                :src="$assetURL('media/logos/google-calendar.png')"
                max-height="100"
                contain
                position="center center"
              ></v-img>
            </v-col>
            <v-col md="10" class="setting-configuration">
              <div>
                <h4 class="font-weight-bold color-custom-blue">
                  Google Calendar
                </h4>
                <p>
                  Integrate with Google Calendar - Automatic sync all visits and
                  reminders.
                </p>
              </div>
              <div>
                <v-btn
                  v-if="googleConnected"
                  v-on:click="googleDisconnect"
                  :loading="googleLoading"
                  :disabled="googleLoading || pageLoading"
                  small
                  class="text-white"
                  color="cyan"
                >
                  <span class="font-size-16 font-weight-600">Disconnect</span>
                </v-btn>
                <v-btn
                  v-else
                  v-on:click="googleConnect"
                  small
                  class="text-white"
                  color="cyan"
                  :loading="googleLoading"
                  :disabled="googleLoading || pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Connect</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        flat
        class="custom-grey-border remove-border-radius mt-4"
        v-if="googleConnected"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="12" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Sync Module-wise Data
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <v-row>
            <v-col md="12" class="setting-configuration">
              <!--  <div>
                <h4 class="font-weight-bold color-custom-blue">
                  Select Module
                </h4>
              </div> -->
              <div>
                <div class="d-flex flex-wrap">
                  <div style="width: 110px">
                    <v-checkbox
                      :disabled="googleLoading || pageLoading"
                      v-model="all_module"
                      v-on:click="selectAll(all_module)"
                      class="mr-2"
                      color="cyan white--text"
                      label="All Module"
                    />
                  </div>
                  <div style="width: 110px">
                    <v-checkbox
                      :disabled="googleLoading || pageLoading"
                      v-model="contract_module"
                      v-on:click="selectChild(contract_module)"
                      class="mr-2"
                      color="cyan white--text"
                      label="Contract"
                    />
                  </div>
                  <div style="width: 110px">
                    <v-checkbox
                      :disabled="googleLoading || pageLoading"
                      v-model="project_module"
                      v-on:click="selectChild(project_module)"
                      class="mr-2"
                      color="cyan white--text"
                      label="Project"
                    />
                  </div>
                  <div style="width: 110px">
                    <v-checkbox
                      :disabled="googleLoading || pageLoading"
                      v-model="visit_module"
                      v-on:click="selectChild(visit_module)"
                      class="mr-2"
                      color="cyan white--text"
                      label="Visit"
                    />
                  </div>
                  <div style="width: 110px">
                    <v-checkbox
                      :disabled="googleLoading || pageLoading"
                      v-model="delivery_module"
                      v-on:click="selectChild(delivery_module)"
                      class="mr-2"
                      color="cyan white--text"
                      label="Delivery"
                    />
                  </div>
                  <v-btn
                    class="custom-bold-button"
                    style="padding: 0 13px"
                    :disabled="
                      !all_module &&
                      !contract_module &&
                      !project_module &&
                      !visit_module &&
                      !delivery_module
                    "
                    color="red white--text"
                    small
                    :loading="googleLoading"
                    label
                    v-on:click="updateGoogleEvent"
                  >
                    <v-icon dark left>mdi-sync</v-icon> Data Sync
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PUT } from "@/core/services/store/request.module";
import { forEach } from "lodash";

export default {
  data() {
    return {
      googleConnected: false,
      googlePopup: null,
      googleTimeInterval: null,
      googleLoading: false,
      pageLoading: false,
      /*   moduleArr :[
          {   
          "field":"all_module",
          "value":"1",
          },
          {
              "field":"contract_module",
              "value":"1",
          },

          {
              "field":"project_module",
              "value":"1",
          },
          {
              "field":"visit_module",
              "value":"1",
          },
        {
            "field":"delivery_module",
            "value":"1",
        }
      ], */
      delivery_module: false,
      visit_module: false,
      project_module: false,
      contract_module: false,
      all_module: false,
    };
  },
  methods: {
    selectAll(checkbox) {
      if (checkbox) {
        (this.delivery_module = true),
          (this.visit_module = true),
          (this.project_module = true),
          (this.contract_module = true),
          (this.all_module = true);
      } else {
        (this.delivery_module = false),
          (this.visit_module = false),
          (this.project_module = false),
          (this.contract_module = false),
          (this.all_module = false);
      }
      /*    this.emitReminderData(); */
    },
    /*   emitReminderData() {
            this.$emit('update:data', {
                all_reminder: this.all_reminder,
                oneMonth_reminder: this.oneMonth_reminder,
                fifteenday_reminder: this.fifteenday_reminder,
                oneweek_reminder: this.oneweek_reminder,
                oneday_reminder: this.oneday_reminder,
                onday_reminder: this.onday_reminder,
                ondayafter_reminder: this.ondayafter_reminder,
            })
        }, */
    selectChild(notcheck) {
      if (
        this.delivery_module &&
        this.visit_module &&
        this.project_module &&
        this.contract_module
      ) {
        this.all_module = true;
      }
      if (!notcheck) {
        this.all_module = false;
      }
      /* this.emitReminderData(); */
    },
    googleConnect() {
      const _this = this;
      _this.googleLoading = true;
      _this.$store
        .dispatch(GET, { url: "google/calendar/url" })
        .then(({ data }) => {
          _this.googleLoading = false;

          _this.googleConnectPopup(data.url);
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    googleCalendarModule() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/google-calnder" })
        .then(({ data }) => {
          forEach(data, (row) => {
            if (row.field == "all_module" && row.value == 1) {
              this.all_module = true;
            }
            if (row.field == "contract_module" && row.value == 1) {
              this.contract_module = true;
            }
            if (row.field == "project_module" && row.value == 1) {
              this.project_module = true;
            }
            if (row.field == "visit_module" && row.value == 1) {
              this.visit_module = true;
            }
            if (row.field == "delivery_module" && row.value == 1) {
              this.delivery_module = true;
            }
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateGoogleEvent() {
      const _this = this;
      _this.googleLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: "google/event",
          data: {
            delivery_module: this.delivery_module,
            visit_module: this.visit_module,
            project_module: this.project_module,
            contract_module: this.contract_module,
            all_module: this.all_module,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.googleLoading = false;
        });
    },
    googleDisconnect() {
      const _this = this;
      _this.googleLoading = true;
      _this.$store
        .dispatch(GET, { url: "google/calendar/disconnect" })
        .then(() => {
          _this.googleConnected = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.googleLoading = false;
        });
    },
    googleConnectPopup(url) {
      let left = screen.width / 2 - 536 / 2;
      let top = screen.height / 2 - 634 / 2;
      this.googlePopup = window.open(
        url,
        "",
        "resizable=yes,top=" + top + ",left=" + left + ",width=536,height=634"
      );
      this.watchGooglePopup();
    },
    checkGoogleStatus() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "google/calendar/status" })
        .then(({ data }) => {
          if (data.status) {
            _this.googleConnected = true;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.googleLoading = false;
          this.googleCalendarModule();
        });
    },
    watchGooglePopup() {
      const _this = this;
      clearInterval(_this.googleTimeInterval);
      _this.googleTimeInterval = setInterval(function () {
        if (_this.googlePopup && _this.googlePopup.closed) {
          _this.googlePopup = null;
          clearInterval(_this.googleTimeInterval);
          setTimeout(function () {
            _this.checkGoogleStatus();
          }, 2000);
        }
      }, 1000);
    },
  },
  mounted() {
    this.checkGoogleStatus();
  },
};
</script>
